import React from 'react';
import { Link } from 'gatsby';

export default () => (
  <footer className="footer-primary wide" role="contentinfo">
    <div className="container cf">
      <Link
        to="/"
        className="logo ampersand-logo transition transition-fadeout pull-left"
        title="&yet's Home Page"
      >
        &yet
      </Link>
      <div className="pull-left">
        <nav role="navigation">
          <ul className="list-inline list-unstyled">
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/jobs">Jobs</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);
