import React from 'react';
import { Link } from 'gatsby';

const Header = props => {
  const { viewClass } = props;
  return (
    <header>

      <div className="header">
        <div className="container header-content">
          <div>
            <Link
              className="logo full-logo transition transition-fadeout"
              to="/"
            >
              &yet
            </Link>
          </div>
          <div className="contact-wrapper">
            <Link
              to="/contact"
              className="contact-btn"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
