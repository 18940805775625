import React from 'react';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Typekit from '../components/Typekit';

import '../styl/main.styl';

export default props => {
  const {
    pageDescription,
    pageTitle,
    bodyClass,
    children,
  } = props;
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="chrome-1" />
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content="&yet, Design, Marketing Design, Illustration, Web Development, Web Design, App Design, App Development, Software Development"
        />
        <meta name="author" content="&yet" />
        <title>
          {pageTitle ? `${pageTitle} — ` : ''}
          &yet
        </title>

        {/* TODO original had "+ jsExt" */}
        {/* <script src='/js/nojs.js' /> */}

        {/* TODO Figure out how to add scripts here */}
        {/* <script src='/js/nojs.js' /> */}

        {/* <script src='/js/respimage.min.js' async /> */}

        {/* TODO: original had recapchta script here, maybe only useful on contact form pages? */}

        <body className={bodyClass} />
      </Helmet>
      <Typekit id="rkd7ezs" />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
